<template>
  <Row type="flex" justify="space-between">

    <Alert type="warning" message="ВНИМАНИЕ! Изменения в разделе не влияют на данные сайта"/>

    <Space>
      <Dropdown :trigger="['click']">
        <Button>Сортировка <DownOutlined/></Button>
        <template #overlay>
          <Menu v-model:selectedKeys="sort" @click="handleSort">
            <MenuItem key="name.asc">
              По имени А-Я
            </MenuItem>
            <MenuItem key="name.desc">
              По имени Я-А
            </MenuItem>
            <MenuItem key="role.asc">
              По роли
            </MenuItem>
            <MenuItem key="created_at.asc">
              По регистрации от старых
            </MenuItem>
            <MenuItem key="created_at.desc">
              По регистрации от новых
            </MenuItem>
          </Menu>
        </template>
      </Dropdown>

      <Dropdown v-model:visible="visibleFilter" :trigger="['click']">
        <Button>Фильтры <DownOutlined/></Button>
        <template #overlay>
          <Menu>
            <MenuItem>
              <Checkbox v-model:checked="filters['filter[role][]=1']" @change="load()">Администраторы</Checkbox>
            </MenuItem>
            <MenuItem>
              <Checkbox v-model:checked="filters['filter[role][]=10']" @change="load()">Менеджер по продажам</Checkbox>
            </MenuItem>
            <MenuItem>
              <Checkbox v-model:checked="filters['filter[role][]=20']" @change="load()">СММ</Checkbox>
            </MenuItem>
            <MenuItem>
              <Checkbox v-model:checked="filters['filter[role][]=30']" @change="load()">Логист</Checkbox>
            </MenuItem>
            <MenuItem>
              <Checkbox v-model:checked="filters['filter[role][]=50']" @change="load()">Пользователи</Checkbox>
            </MenuItem>
          </Menu>
        </template>
      </Dropdown>

      <!--      <Dropdown v-model:visible="visibleExport" :trigger="['click']">-->
      <!--        <template #overlay>-->
      <!--          <Menu>-->
      <!--            <MenuItem key="1">-->
      <!--              <FileExcelOutlined/>-->
      <!--              .xlsx-->
      <!--            </MenuItem>-->
      <!--            <MenuItem key="2">-->
      <!--              <FileOutlined/>-->
      <!--              .csv-->
      <!--            </MenuItem>-->
      <!--          </Menu>-->
      <!--        </template>-->
      <!--        <Button size="">-->
      <!--          Экспорт-->
      <!--          <DownOutlined/>-->
      <!--        </Button>-->
      <!--      </Dropdown>-->
    </Space>
  </Row>
  <br>

  <Card title="" class="table">
    <Table :columns="columns" :data-source="data" :pagination="pagination" :loading="loading" rowKey="id"
           @change="handleTableChange">

      <template #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
        <div class="dropdown-search-panel">
          <Input :placeholder="`Поиск по ${column.title}`" :value="selectedKeys[0]"
                 @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                 @pressEnter="confirm"/>
          <Space>
            <Button type="primary" @click="confirm">
              <template #icon>
                <SearchOutlined/>
              </template>
              Найти
            </Button>
            <Button @click="clearFilters">Сбросить</Button>
          </Space>
        </div>
      </template>
      <template #filterIcon="{ filtered }">
        <SearchOutlined :style="{background: filtered?'rgba(16,142,233,0.1)':'', marginRight: '10px'}"/>
      </template>

      <template #name="{ record }">
        <Space>
          <Avatar>
            <template #icon>
              <UserOutlined/>
            </template>
          </Avatar>
          <div>
            {{ record.name }}<br>
            <TypographyText type="secondary">{{ record.email }}</TypographyText>
          </div>
        </Space>
      </template>
      <template #role_info="{ text: role_info, record }">
        <Dropdown :trigger="['click']">
          <Tag :color="role_info.color" style="cursor: pointer">{{ role_info.name }}</Tag>
          <template #overlay>
            <Menu @click="({key:value}) => role(value, record)">
              <MenuItem key="0" disabled>
                Сменить роль:
              </MenuItem>
              <MenuDivider />
              <MenuItem key="1">
                Администратор
              </MenuItem>
              <MenuItem key="10">
                Менеджер по продажам
              </MenuItem>
              <MenuItem key="20">
                СММ
              </MenuItem>
              <MenuItem key="30">
                Логист
              </MenuItem>
              <MenuItem key="50">
                Пользователь
              </MenuItem>
            </Menu>
          </template>
        </Dropdown>
      </template>
      <template #actions="{ text: id, record }">
        <Space>
          <Popconfirm :title="(record.status ? 'Заблокировать' : 'Разблокировать') + ' пользователя?'"
                      placement="topLeft" @confirm="status(record)">
            <Button type="text" class="silver-color"><template #icon>
              <UnlockFilled v-if="record.status"/>
              <LockFilled style="color: red" v-else/>
            </template></Button>
          </Popconfirm>
          <Button type="text" class="silver-color" @click="show_edit = !show_edit; id_edit = id">
            <template #icon><FormOutlined/></template>
          </Button>
          <Popconfirm title="Удалить запись?" placement="topLeft" @confirm="remove(id)">
            <Button type="text" class="silver-color"><template #icon><DeleteFilled/></template></Button>
          </Popconfirm>
        </Space>
      </template>
    </Table>
  </Card>

  <User :show="show_edit" :id="id_edit" @close="show_edit = false" @update="val => { id_edit = val; load() }"/>

</template>

<script setup>
import {
  Alert,
  notification,
  Card,
  Table,
  Avatar,
  TypographyText,
  Tag,
  Space,
  Popconfirm,
  Button,
  Checkbox,
  Input,
  Row,
  Dropdown,
  Menu,
  MenuItem,
  MenuDivider
} from "ant-design-vue";
import {
  UserOutlined,
  FormOutlined,
  DeleteFilled,
  DownOutlined,
  FileExcelOutlined,
  FileOutlined,
  SearchOutlined,
  LockFilled,
  UnlockFilled
} from "@ant-design/icons-vue";
import {ref, reactive} from "vue";
import User from '../../components/User';

const columns = [
  {
    title: 'Имя / Email',
    dataIndex: 'name',
    slots: {
      customRender: 'name',
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
    },
  },
  {
    title: 'Роль',
    dataIndex: 'role_info',
    slots: {
      customRender: 'role_info',
    },
  },
  {
    title: 'Дата регистрации',
    dataIndex: 'created_at',
  },
  {
    align: 'right',
    dataIndex: 'id',
    slots: {
      customRender: 'actions',
    },
  },
];

const visibleFilter = ref(false)
const filters = reactive({
  'filter[role][]=1': false,
  'filter[role][]=10': false,
  'filter[role][]=20': false,
  'filter[role][]=30': false,
  'filter[role][]=50': false,
})

const sort = ref(['created_at.desc'])
const handleSort = ({key}) => {
  sort.value = [key]
  load()
}
let search = {}
const pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10
})
const loading = ref(false)
const data = ref([])
const load = async () => {
  loading.value = true
  let query = `page=${pagination.current}&order=${sort.value}&`
      + `${Object.keys(search).map(key => key + '=' + search[key]).join('&')}&`
      + `${Object.keys(filters).map(key => { if(filters[key]) return key }).filter(v => v).join('&')}`
  await $axios.get(`users?${query}`).then(r => {
    data.value = r.data.data
    pagination.total = r.data.total
    pagination.pageSize = r.data.per_page
  }).catch(er => {
    if (er.response) notification.open({
      class: 'error',
      message: er.response.status,
      description: er.response.statusText,
    })
  })
  loading.value = false
}
load()

const handleTableChange = (pag, filters) => {
  pagination.current = pag.current
  search = filters
  load()
}

const role = async (value, record) => {
  loading.value = true
  await $axios.post(`users/${record.id}/role`, {value}).then(r => {
    record.role = r.data.role
    record.role_info = r.data.role_info
    notification.open({
      class: 'success',
      message: 'Готово',
      description: 'Роль изменена',
    })
  }).catch(er => {
    if (er.response) notification.open({
      class: 'error',
      message: er.response.status,
      description: er.response.statusText,
    })
  })
  loading.value = false
}
const status = async (record) => {
  loading.value = true
  await $axios.post(`users/${record.id}/status`, {value: !record.status}).then(r => {
    record.status = r.data.status
    notification.open({
      class: r.data.status ? 'success' : 'warning',
      message: 'Готово',
      description: 'Пользователь '+(r.data.status ? 'разблокирован' : 'заблокирован'),
    })
  }).catch(er => {
    if (er.response) notification.open({
      class: 'error',
      message: er.response.status,
      description: er.response.statusText,
    })
  })
  loading.value = false
}
const remove = async (id) => {
  loading.value = true
  await $axios.delete(`users/${id}`).then(() => {
    load()
    notification.open({
      class: 'warning',
      message: 'Готово',
      description: 'Пользователь удален',
    })
  }).catch(er => {
    if (er.response) notification.open({
      class: 'error',
      message: er.response.status,
      description: er.response.statusText,
    })
    loading.value = false
  })
}

const show_edit = ref(false)
const id_edit = ref('add')

</script>

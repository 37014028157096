<template>
  <Drawer :title="edit ? 'Редактировать' : 'Создать'" :width="width" v-model:visible="visible"
          :body-style="{ paddingBottom: '80px' }">

    <Skeleton active v-if="loading"/>
    <Form v-else :model="formState" :rules="rules" layout="vertical" @finish="submit">
      <Card>
        <Row type="flex" justify="space-between" align="middle">
          <Avatar shape="square" :size="80">
            <template #icon>
              <UserOutlined/>
            </template>
          </Avatar>
          <Tooltip>
            <template #title>{{ formState.status ? 'Разблокирован' : 'Заблокирован' }}</template>
            <Button type="text" class="silver-color" size="large" @click="formState.status = !formState.status">
              <template #icon>
                <UnlockFilled :style="{ fontSize: '32px' }" v-if="formState.status"/>
                <LockFilled style="color: red" :style="{ fontSize: '32px' }" v-else/>
              </template>
            </Button>
          </Tooltip>
        </Row>
      </Card>
      <br><br>

      <Card title="Общие данные">
        <Row :gutter="16">
          <Col :span="12">
            <FormItem name="name" label="Имя">
              <Input v-model:value="formState.name" size="large" placeholder="Введите имя"/>
            </FormItem>
          </Col>
          <Col :span="12">
            <FormItem name="email" label="Email">
              <Input v-model:value="formState.email" size="large" placeholder="Введите email"
                     @blur="customError.email = ''"/>
              <Alert style="margin-top: 5px" type="error" v-if="customError.email" :message="customError.email" banner/>
            </FormItem>
          </Col>
          <Col :span="12">
            <FormItem label="Роль">
              <Select size="large" name="role" v-model:value="formState.role" placeholder="Выберите роль в системе">
                <SelectOption :value="1">Администратор</SelectOption>
                <SelectOption :value="10">Менеджер по продажам</SelectOption>
                <SelectOption :value="20">СММ</SelectOption>
                <SelectOption :value="30">Логист</SelectOption>
                <SelectOption :value="50">Пользователь</SelectOption>
              </Select>
            </FormItem>
          </Col>
          <Col :span="12" v-if="!edit">
            <FormItem name="password" label="Пароль">
              <InputPassword v-model:value="formState.password" size="large" placeholder="Введите пароль"/>
            </FormItem>
          </Col>

          <Col :span="24">
            <Button type="primary" size="large" html-type="submit" :loading="saving">Сохранить</Button>
          </Col>
        </Row>
      </Card>
    </Form>
    <br><br>

    <Form :model="formStatePassword" :rules="rulesPassword" layout="vertical" @finish="updatePassword"
          v-if="!loading && edit">
      <Card title="Изменить пароль">
        <Row :gutter="16">
          <Col :span="24">
            <FormItem name="new_password" label="Пароль">
              <InputPassword v-model:value="formStatePassword.new_password" size="large" placeholder="Введите новый пароль"/>
            </FormItem>
          </Col>
          <Col :span="24">
            <Button type="primary" size="large" html-type="submit" :loading="saving_pass">Обновить пароль</Button>
          </Col>
        </Row>
      </Card>
    </Form>

  </Drawer>
</template>

<script setup>
import {
  Skeleton,
  Drawer,
  Card,
  Row,
  Col,
  Avatar,
  Form,
  FormItem,
  Select,
  SelectOption,
  InputPassword,
  Input,
  Button,
  Tooltip,
  Space, notification, Alert
} from "ant-design-vue";
import {reactive, ref, toRaw, toRefs, watch, getCurrentInstance} from 'vue';
import {UserOutlined, UnlockFilled, LockFilled} from '@ant-design/icons-vue';

const {emit} = getCurrentInstance()
const props = defineProps({
  show: {
    type: Boolean,
    require: true
  },
  id: {
    type: [String, Number],
    required: true
  }
})

const formState = reactive({
  status: true,
  role: 50,
  name: '',
  email: '',
  password: ''
});
const rules = {
  name: [
    {
      required: true,
      min: 2,
      message: 'Не менее 2 символов',
      trigger: 'blur',
    },
  ],
  email: [
    {
      required: true,
      message: 'Не может быть пустым',
      trigger: 'blur',
    },
    {
      type: 'email',
      message: 'Не верный формат адреса',
      trigger: 'blur',
    },
  ],
  password: [
    {
      required: true,
      min: 2,
      message: 'Не менее 2 символов',
      trigger: 'blur',
    },
  ]
};
const customError = reactive({})

const {show, id} = toRefs(props)
const width = ref(1054);
const visible = ref(false)
const loading = ref(false)
const edit = ref(false)
watch(show, async val => {
  let w = window.innerWidth - window.innerWidth * 0.2
  width.value = w > 1054 ? 1054 : w
  visible.value = val
  edit.value = id.value !== 'add'

  if(val){
    if (id.value === 'add') {
      formState.status = true
      formState.role = 50
      formState.name = ''
      formState.email = ''
      formState.password = ''
      formStatePassword.new_password = ''
    }else{
      loading.value = true
      await $axios.get('users/'+id.value).then(r => {
        formState.status = r.data.status
        formState.role = r.data.role
        formState.name = r.data.name
        formState.email = r.data.email
      }).catch(error => {
        notification.open({
          class: 'error',
          message: error.response.status,
          description: error.response.statusText,
        })
      })
      loading.value = false
    }
  }
})

const saving = ref(false)
const submit = async () => {
  saving.value = true
  await $axios.post('users' + (edit.value ? `/${id.value}` : ''), toRaw(formState)).then(r => {
    emit('update', r.data.id)
    notification.open({
      class: 'success',
      message: 'Готово',
      description: 'Данные сохранены'
    })
  }).catch(error => {
    if (error.response) {
      if (error.response.status === 422) {
        try {
          for (const [key, value] of Object.entries(error.response.data.errors)) customError[key] = value
        } catch (error) {
          console.info(error)
        }
      } else {
        notification.open({
          class: 'error',
          message: error.response.status,
          description: error.response.statusText,
        })
      }
    }
  })
  saving.value = false
}

const formStatePassword = reactive({
  new_password: ''
});
const rulesPassword = {
  new_password: [
    {
      required: true,
      min: 2,
      message: 'Не менее 2 символов',
      trigger: 'blur',
    },
  ]
};

const saving_pass = ref(false)
const updatePassword = async () => {
  saving_pass.value = true
  await $axios.post('users/' + id.value + '/password', toRaw(formStatePassword)).then(() => {
    formStatePassword.new_password = ''
    notification.open({
      class: 'success',
      message: 'Готово',
      description: 'Данные сохранены'
    })
  }).catch(error => {
    if (error.response) {
      notification.open({
        class: 'error',
        message: error.response.status,
        description: error.response.statusText,
      })
    }
  })
  saving_pass.value = false
}

</script>
